<template>
    <div>
        <v-carousel
            v-if="value !== null && typeof(value) !== 'undefined' && value.length > 0"
            height="auto"
            :show-arrows-on-hover="true">
            <v-carousel-item v-for="(fileId, i) in fileIds" :key="i">
                <app-file-image :value="fileId" :is-hover="true" />
            </v-carousel-item>
        </v-carousel>
        <v-card v-else>
            <v-card-title>
                <span class="headline">
                    No Photos
                </span>
            </v-card-title>
            <v-card-text>
                There are no photos to display
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1" />
                <v-btn color="quaternary" text @click="$emit('close')">
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="!isReadonly && !isDisabled"
                    color="primary"
                    dark
                    v-on="on"
                    @click="dialog = true">
                    Upload File
                </v-btn>
            </template>

            <app-form
                :key="key"
                :schema="getSchema(fileTableName)"
                :filter="filter"
                @cancel="dialog = false"
                @open="dialog = true"
                @input="onInput" />
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
        appFileImage: () => import('@/components/AppFileImage'),
        appForm: () => import('@/components/AppForm')
    },
    props: {
        value: {
            type: Array,
            default: null
        },
        filter: {
            type: Object,
            default: null
        },
        isReadonly: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        fileTableName: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            dialog: false,
            fileIds: [],
            key: 0
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.fileIds = this.value === null ? [] : this.value;
            }
        }
    },
    methods: {
        onInput(value) {
            this.dialog = false;
            this.fileIds.push(value.fileId);
            this.$emit('input', this.fileIds);
            this.key++;
        }
    }
};
</script>
